<template>
  <div>
    <!-- <div class="role-title">{{ '角色：超级管理员' }}</div> -->
    <div class="destri-cont">
      <a-row class="search-expand user-add">
        <a-col :span="8" v-for="item in Items" :key="item.value">
          <div>{{ item.label }}</div>
          <!-- 输入 -->
          <a-input
            v-show="item.type === 'input'"
            v-model="form[item.value]"
            class="search-expand-value"
            :placeholder="
              (item.type === 'input' ? '请输入' : '请选择') + item.label
            "
          />
          <a-select
            v-show="item.type === 'selectCompanies'"
            v-model="form[item.value]"
            :placeholder="
              (item.type === 'input' ? '请输入' : '请选择') + item.label
            "
            class="search-expand-value"
            default-value="0"
          >
            <a-select-option
              v-for="opt in filters[item.selectOption]"
              :key="opt.bc_id"
              :value="opt.bc_id"
              >{{ opt.bc_name }}</a-select-option
            >
          </a-select>
          <a-select
            v-show="item.type === 'PSystem'"
            v-model="form[item.value]"
            :placeholder="
              (item.type === 'input' ? '请输入' : '请选择') + item.label
            "
            class="search-expand-value"
          >
            <a-select-option
              v-for="opt in filters[item.selectOption]"
              :key="opt.value"
              :value="opt.value"
              >{{ opt.name }}</a-select-option
            >
          </a-select>
          <a-select
            mode="multiple"
            v-show="item.type == 'r_belong_systems'"
            :placeholder="
              (item.type === 'r_belong_systems' ? '请输入' : '请选择') +
              item.label
            "
            v-model="form[item.value]"
            @change="handleChangeBelongStystems"
            class="search-expand-value"
          >
            <a-select-option
              v-for="opt in filters[item.selectOption]"
              :key="opt.value"
              :value="opt.value"
            >
              {{ opt.name }}
            </a-select-option>
          </a-select>
        </a-col>
      </a-row>

      <div class="page-title" v-if="pagesShow">
        <span>现场管理可见页面</span>
        <a-checkbox
          :indeterminate="indeterminate"
          :checked="checkAll"
          @change="onCheckAllChange"
          >全选</a-checkbox
        >
      </div>
      <div class="page-title" v-if="!pagesShow">
        <span>交付系统可见页面</span>
        <a-checkbox
          :indeterminate="indeterminateJF"
          :checked="checkAllJF"
          @change="onCheckAllChangeJF"
          >全选</a-checkbox
        >
      </div>
      <!-- 树形控件 -->
      <!--现场管理系统 -->
      <a-tree
        v-if="pages.length && pagesShow"
        v-model="checkedKeys"
        checkable
        :expanded-keys="expandedKeys"
        :auto-expand-parent="autoExpandParent"
        :selected-keys="selectedKeys"
        :tree-data="pages"
        @expand="onExpand"
        @select="onSelect"
        :checkStrictly="true"
        :defaultExpandAll="true"
      />
      <!-- 交付系统 -->
      <a-tree
        v-if="pagesJF.length && !pagesShow"
        v-model="checkedKeysJF"
        checkable
        :expanded-keys="expandedKeysJF"
        :auto-expand-parent="autoExpandParentJF"
        :selected-keys="selectedKeysJF"
        :tree-data="pagesJF"
        @expand="onExpandJF"
        @select="onSelectJF"
        :checkStrictly="true"
        :defaultExpandAll="true"
      />
      <!-- 返回确认按钮 -->

      <a-affix :offset-bottom="40">
        <div class="dest-bottom" style="margin-top: 20px">
          <a-button @click="viewCancel()">返回</a-button>
          <a-button @click="editSubmit()" type="primary">确认</a-button>
        </div>
      </a-affix>
      <!-- <div class="dest-bottom" style="margin-top: 20px">
        <a-button @click="viewCancel()">返回</a-button>
        <a-button @click="editSubmit()" type="primary">确认</a-button>
      </div> -->
    </div>
  </div>
</template>
<script>
import Page from "@/utils/page";
import { PageJF } from "@/utils/page";
import { AddRole, EditRole } from "@/api/role";
import { GetCompanies } from "@/api/device";

const editParam = [
  "r_id",
  "r_name",
  "r_describe",
  "r_right",
  "r_bc_id",
  "r_belong_systems",
];
const addParam = [
  "r_name",
  "r_describe",
  "r_right",
  "r_bc_id",
  "r_belong_systems",
];
export default {
  name: "RoleEdit",
  props: {
    roledata: {
      type: Object,
      default: {},
    },
    childPage: {
      type: String,
    },
  },
  data() {
    return {
      pages: Page,
      pagesJF: PageJF,
      subPages: [],
      subPagesJF: [],
      // 全选
      checkAll: false,
      checkAllJF: false,
      indeterminate: false,
      indeterminateJF: false,
      checkedList: [],
      checkedListJF: [],
      filters: {
        GetCompanies: [],
        PSystemTypes: [
          {
            value: "xc",
            name: "现场管理",
          },
          {
            value: "jf",
            name: "交付管理",
          },
        ],
        r_belong_systemsList: [
          {
            value: "osmcore",
            name: "现场管理",
          },
          {
            value: "dsmcore",
            name: "交付系统",
          },
          {
            value: "scmcore",
            name: "供应链",
          },
        ],
      },
      //输入的文本框
      Items: [
        { label: "角色名称", value: "r_name", type: "input" },
        { label: "角色描述", value: "r_describe", type: "input" },
        {
          label: "分公司",
          value: "r_bc_id",
          type: "selectCompanies",
          selectOption: "GetCompanies",
        },
        {
          label: "系统选择",
          value: "PSystem",
          type: "PSystem",
          selectOption: "PSystemTypes",
        },
        {
          label: "平台权限",
          value: "r_belong_systems",
          type: "r_belong_systems",
          selectOption: "r_belong_systemsList",
        },
      ],
      form: {},
      expandedKeys: [], //受控展开的节点
      expandedKeysJF: [],
      autoExpandParent: true, //是否自动展开父节点
      autoExpandParentJF: true,
      checkedKeys: {},
      checkedKeysJF: {},
      selectedKeys: [], //设置选中的父节点
      selectedKeysJF: [],
      treeData: [],
      addShow: false,
      pagesShow: true,
    };
  },
  created() {
    // 获取分公司信息
    GetCompanies().then((res) => {
      // console.log("GetCompanies", res.data);
      this.filters.GetCompanies = res.data;
    });
    // this.pages = JSON.parse(JSON.stringify(Page));
    if (this.childPage === "add") {
      this.form = {};
    } else if (this.childPage === "edit") {
      this.$set(this.form, "r_id", this.roledata.r_id);
      this.$set(this.form, "r_name", this.roledata.r_name);
      this.$set(this.form, "r_describe", this.roledata.r_describe);
      this.$set(this.form, "r_bc_id", this.roledata.r_bc_id);
      this.$set(
        this.form,
        "r_belong_systems",
        this.roledata.r_belong_systems.split(",")
      );

      this.checkedList = this.roledata.r_right.split(",");
      this.checkedListJF = this.roledata.r_right.split(",");
      this.checkedKeys.checked = this.roledata.r_right.split(",");
      this.checkedKeysJF.checked = this.roledata.r_right.split(",");
      // console.log("this.roledata.r_right", this.roledata.r_right);
      // this.expandedKeys = this.roledata.r_right.split(",");
    }
    // console.log(this.roledata.r_right);
  },
  mounted() {
    this.pages.forEach((el) => {
      this.subPages = this.subPages.concat(el.children);
      // 新加角色添加页面  打开所有节点
      let list = [];
      this.subPages.forEach((el) => {
        list.push(el.key);
        if (el.children) {
          el.children.forEach((item) => {
            list.push(item.key);
          });
          // console.log("el.children", el.children); // el.children
        }
      });
      this.expandedKeys = list;
    });
    this.pagesJF.forEach((el) => {
      this.subPagesJF = this.subPagesJF.concat(el.children);
      // 新加角色添加页面  打开所有节点
      let list = [];
      this.subPagesJF.forEach((el) => {
        list.push(el.key);
        if (el.children) {
          el.children.forEach((item) => {
            list.push(item.key);
          });
          // console.log("el.children", el.children); // el.children
        }
      });
      this.expandedKeysJF = list;
    });

    // console.log(
    //   "pages",
    //   this.pages,
    //   this.pagesJF,
    //   this.subPages,
    //   this.subPagesJF
    // );
  },
  watch: {
    checkedKeys(val) {
      // console.log("checkedKeys", val);
    },
    checkedList(val) {
      // console.log("checkedList", val);
    },
    expandedKeys(val) {
      // console.log("expandedKeys", val);
      let newList = [];
      this.checkedList.forEach((el) => {
        if (val.indexOf(el) > -1) {
          newList.push(el);
        } else {
          // console.log(el);
        }
      });
      // console.log("newList", newList);
      this.checkedKeys.checked = newList;
    },
    expandedKeysJF(val) {
      // console.log("expandedKeys", val);
      let newList = [];
      this.checkedListJF.forEach((el) => {
        if (val.indexOf(el) > -1) {
          newList.push(el);
        } else {
          // console.log(el);
        }
      });
      // console.log("newList", newList);
      this.checkedKeysJF.checked = newList;
    },
    "$store.state.user.system"(newValue) {
      // console.log("watch--vuex-system", newValue);
      // console.log("watch--vuex-rolesJF", this.$store.state.user.rolesJF);
      if (newValue == "xc") {
        // this.pages = Page;
      } else if (newValue == "jf") {
        // this.pages = PageJF;
      }
    },
    "form.PSystem"(val) {
      // console.log("form.PSystem", val);
      if (val == "xc") {
        this.pagesShow = true;
        // this.pages = Page;
      } else if (val == "jf") {
        this.pagesShow = false;
        // this.pages = PageJF;
      }
    },

    deep: true,
    immediate: true,
  },
  methods: {
    // // 全选
    onCheckAllChange(e) {
      this.indeterminate = false;
      this.checkAll = e.target.checked;
      // console.log(" this.subPages", this.subPages);
      if (e.target.checked) {
        let list = [];
        this.subPages.forEach((el) => {
          list.push(el.key);
          if (el.children) {
            el.children.forEach((item) => {
              list.push(item.key);
            });
            // console.log("el.children", el.children); // el.children
          }
        });
        // console.log("subPages", this.subPages);

        this.checkedList = list;
        this.checkedKeys = list;
        // console.log("checked-checkedKeys", this.checkedKeys);
      } else {
        this.checkedList = [];
        this.checkedKeys = [];
      }
    },
    onCheckAllChangeJF(e) {
      this.indeterminateJF = false;
      this.checkAllJF = e.target.checked;
      console.log("e.target.checked", e.target.checked, this.subPagesJF);
      if (e.target.checked) {
        let listJF = [];
        this.subPagesJF.forEach((el) => {
          listJF.push(el.key);
          if (el.children) {
            el.children.forEach((item) => {
              listJF.push(item.key);
            });
            // console.log("el.children", el.children); // el.children
          }
        });

        this.checkedListJF = listJF;
        this.checkedKeysJF = listJF;
        // console.log("checked-checkedKeys", this.checkedKeys);
      } else {
        this.checkedListJF = [];
        this.checkedKeysJF = [];
      }
    },
    // onChangeCheck(checkedList) {
    //   this.indeterminate =
    //     !!checkedList.length && checkedList.length < this.subPages.length;
    //   this.checkAll = checkedList.length === this.subPages.length;
    // },
    // 确认
    editSubmit() {
      let param = this.form;
      let r_belong_systems = param.r_belong_systems.join(",");
      console.log(param);
      // console.log(" this.checkedKeys", this.checkedKeys);
      // console.log(" this.checkedKeysJF", this.checkedKeysJF);
      let valueXC = this.checkedKeys.checked
        ? this.checkedKeys.checked.join(",")
        : this.checkedKeys.join(",");
      let valueJF = this.checkedKeysJF.checked
        ? this.checkedKeysJF.checked.join(",")
        : this.checkedKeysJF.join(",");
      // console.log(valueXC, valueJF);
      let value = valueXC + "," + valueJF;
      // console.log(value);
      if (this.childPage === "add") {
        if (param.r_bc_id) {
          //添加角色
          // param.r_right = this.checkedList.join(",");
          // let value = this.checkedKeys;
          // console.log("value", value);
          param.r_right = value;
          param.r_belong_systems = r_belong_systems;
          // param.r_right = value.join(",");
          // console.log("params", params);
          // console.log(" param.r_right", param.r_right);
          // param = {
          //   r_name: this.form.r_name,
          //   r_describe: this.form.r_describe,
          //   r_right: this.form.r_right,
          //   r_bc_id: this.form.r_bc_id,
          //   r_belong_systems: r_belong_systems,
          // };
          const params = {
            ...param,
            verify: this.$verify(param, addParam),
          };
          console.log("addparam", param);
          AddRole(params)
            .then((res) => {
              if (res.code == "200") {
                this.$message.success(res.data);
              } else {
                this.$message.error(res.data);
              }
            })
            .then(() => {
              this.$emit("onClose");
            });
        } else {
          this.$message.error("请选择分公司");
        }
        //刷新页面
      } else if (this.childPage === "edit") {
        //修改角色
        // param.r_right = this.checkedList.join(",");
        // let value = this.checkedKeys;
        // console.log("checkedKeys", this.checkedKeys);
        // console.log("value", value); //选中之后传给后端的值
        param.r_belong_systems = r_belong_systems;
        let data = {
          r_right: value,
        };

        // console.log(data);
        const param1 = Object.assign({}, param);
        const requestParameters = this.$order(param1, editParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, editParam),
        };
        console.log("params", params);

        EditRole(params, data)
          .then((res) => {
            if (res.code == "200") {
              this.$message.success(res.data);
            } else {
              this.$message.error(res.data);
            }
          })
          .then(() => {
            this.$emit("onClose");
          });
      }

      // this.$emit("onClose");
    },
    // 取消
    viewCancel() {
      this.$emit("onClose");
    },
    // 展开列表
    onExpand(expandedKeys) {
      // console.log("onExpand", expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onExpandJF(expandedKeys) {
      // console.log("onExpand", expandedKeys);
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeysJF = expandedKeys;
      this.autoExpandParentJF = false;
    },
    onCheck(checkedKeys) {
      // console.log("onCheck", checkedKeys);
      this.checkedKeys = checkedKeys;
    },
    onCheckJF(checkedKeys) {
      // console.log("onCheck", checkedKeys);
      this.checkedKeysJF = checkedKeys;
    },
    onSelect(selectedKeys, info) {
      // console.log("onSelect", info);
      this.selectedKeys = selectedKeys;
    },
    onSelectJF(selectedKeys, info) {
      // console.log("onSelect", info);
      this.selectedKeysJF = selectedKeys;
    },
    // 选择平台
    handleChangeBelongStystems(selectedItems) {
      // this.selectedItems = selectedItems;
      console.log("平台权限", selectedItems);
    },
  },
};
</script>
<style lang="less" scoped>
.destri-cont {
  padding: 24px;
  background-color: #fff;
}
.dest-bottom {
  text-align: right;
  button {
    margin-left: 16px;
  }
}
.role-title {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-bottom: 24px;
}
.page-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
}
.page-cont + .page-cont {
  margin-top: 40px;
}
.item-title {
  font-weight: 600;
  margin-bottom: 12px;
}
/deep/.ant-tree-checkbox-disabled {
  display: none;
}
/deep/li.ant-tree-treenode-disabled > .ant-tree-node-content-wrapper span {
  color: black;
  font-size: 14px;
}
</style>
