const Page = [
  {
    title: "首页",
    key: "0",
    disabled: true,
    children: [
      {
        title: "首页",
        key: "HomeMaster",
        children: [
          { title: "我要掌握", key: "HomeMaster-/api/worksite/worklist" },
        ],
      },
    ],
  },
  {
    title: "数据看板",
    key: "1",
    disabled: true,
    children: [
      // { title: "数据看板", key: "DailyMonitor" },
      { title: "管理看板", key: "BoardTotal" },
      {
        title: "工地巡查大屏",
        key: "Patrol",
        children: [
          { title: "取消样板工地", key: "Patrol-/api/worksite/model" },
          { title: "保存预置位", key: "Patrol-/api/worksite/camera/preset" },
          { title: "返回预设位", key: "Patrol-/api/worksite/camera/reset" },
          { title: "设备重启", key: "Patrol-/api/worksite/camera/restart" },
        ],
      },
      { title: "展厅数据看板", key: "ExhibitionBoard" }, //ExhibitionBoard
      {
        title: "工地案场",
        key: "CaseField",
        children: [
          { title: "取消样板工地", key: "CaseField-/api/worksite/model" },
          { title: "保存预置位", key: "CaseField-/api/worksite/camera/preset" },
          { title: "返回预设位", key: "CaseField-/api/worksite/camera/reset" },
          { title: "设备重启", key: "CaseField-/api/worksite/camera/restart" },
        ],
      },
      {
        title: "展厅案场",
        key: "customPreview",
        children: [
          { title: "取消样板工地", key: "customPreview-/api/worksite/model" },
        ],
      }, //id名要和给后端的名字一样
      // { title: "AI可视化看板", key: "DataV" },
      { title: "旧版管理端看板", key: "DataV" },
      {
        title: "地图案场看板",
        key: "MapFieldboard",
      },
      {
        title: "直播案场",
        key: "VideoRecordView",
        children: [
          { title: "取消样板工地", key: "VideoRecordView-/api/worksite/model" },
        ],
      },
    ],
  },
  {
    title: "工地管理",
    key: "2",
    disabled: true,
    children: [
      // { title: "定制地图", key: "CustomMap" },
      {
        title: "工地列表",
        key: "SiteList",
        children: [
          { title: "新建工地", key: "SiteList-/api/worksite/addworksite" },
          { title: "导出", key: "SiteList-/api/worksite/workexport" },
          { title: "详情", key: "SiteList-/api/worksite/detail" },
          { title: "直播", key: "SiteList-/api/worksite/camera/video" },
          { title: "保存预置位", key: "SiteList-/api/worksite/camera/preset" },
          { title: "返回预设位", key: "SiteList-/api/worksite/camera/reset" },
          { title: "设备重启", key: "SiteList-/api/worksite/camera/restart" },
          // {
          //   title: "查看预置位历史记录",
          //   key: "SiteList-/api/worksite/camera/restart",
          // },
          { title: "快照", key: "SiteList-/api/worksite/snapshot" },
          { title: "回放", key: "SiteList-/api/worksite/playbackstart" },
          { title: "考勤", key: "SiteList-/api/worksite/attendance" },
          { title: "修改", key: "SiteList-/api/worksite/updateworksite" },
          { title: "设备绑定", key: "SiteList-/api/qzw/devicebind" },
          { title: "解绑", key: "SiteList-/api/qzw/deviceunbound" },
          { title: "删除", key: "SiteList-/api/worksite/delws" },
        ],
      },
      {
        title: "施工地图",
        key: "SiteMap",
        children: [
          { title: "直播", key: "SiteMap-/api/worksite/camera/video" },
          { title: "工地详情", key: "SiteMap-/api/worksite/mapsitedetail" },
          { title: "快照", key: "SiteMap-/api/worksite/snapshot" },
          { title: "考勤", key: "SiteMap-/api/worksite/attendance" },
          { title: "回放", key: "SiteMap-/api/worksite/playbackstart" },
        ],
      },
      // { title: "暂停施工", key: "SiteSuspend" },
      {
        title: "日报管理",
        key: "DailyList",
        children: [{ title: "导出", key: "DailyList-/api/device/import" }],
      },
      {
        title: "样板工地管理",
        key: "SampleSite",
        children: [
          { title: "设置样板工地", key: "SampleSite-/api/worksite/model" },
          { title: "直播", key: "SampleSite-/api/worksite/camera/video" },
          // { title: "查看回放", key: "SampleSite-/api/worksite/playbackstart" },
        ],
      },
    ],
  },
  {
    title: "人员管理",
    key: "3",
    disabled: true,
    children: [
      {
        title: "人员考勤",
        key: "WorkerAttend",
        children: [
          // { title: "查看考勤统计", key: "WorkerAttend-/api/worksite/detail" },
          { title: "导出", key: "WorkerAttend-/api/worker/attendanceexport" },
          { title: "考勤日历", key: "WorkerAttend-/api/worker/calendar" },
        ],
      },
      {
        title: "人员列表",
        key: "WorkerList",
        children: [
          { title: "导出", key: "WorkerList-/api/worker/workerexport" },
          { title: "新增", key: "WorkerList-/api/worker/addworker" },
          { title: "编辑", key: "WorkerList-/api/worker/updateworker" },
          { title: "删除", key: "WorkerList-/api/worker/delworker" },
        ],
      },
      // { title: "人员考勤统计", key: "WorkerAttendStatistics" },
    ],
  },
  {
    title: "工地形象",
    key: "4",
    disabled: true,
    children: [
      {
        title: "报警事件管理",
        key: "AlarmEvent",
        children: [
          // { title: "批量审核照片", key: "AlarmEvent-/api/snapshot/pickup" },
          { title: "导出", key: "AlarmEvent-/api/worker/eventexport" },
          { title: "直播", key: "AlarmEvent-/api/worksite/camera/video" },
          { title: "纠正", key: "AlarmEvent-/api/snapshot/pickup" },
        ],
      },
      {
        title: "整洁管理",
        key: "SiteNeat",
        children: [
          // { title: "批量审核照片", key: "SiteNeat-/api/snapshot/pickup" },
          { title: "导出", key: "SiteNeat-/api/worker/imageexport" },
          { title: "直播", key: "SiteNeat-/api/worksite/camera/video" },
          { title: "纠正", key: "SiteNeat-/api/snapshot/pickup" },
          // { title: "纠正", key: "SiteNeat-/api/snapshot/pickup" },
        ],
      },
      {
        title: "纠正日志",
        key: "CorrectionLog",
        children: [],
      },
    ],
  },
  {
    title: "数据统计",
    key: "5",
    disabled: true,
    children: [
      { title: "日报", key: "DailySheet" },
      {
        title: "考勤统计",
        key: "AttendStatistics",
        children: [
          {
            title: "按人员统计导出",
            key: "AttendStatistics-/api/count/acountworkerexport",
          },
          {
            title: "按工地统计导出",
            key: "AttendStatistics-/api/count/acountwsexport",
          },
          // { title: "查看详情", key: "AttendStatistics-" }, //跳到了人员考勤页面
        ],
      },
      {
        title: "工地形象统计",
        key: "SiteImage",
        children: [
          {
            title: "按组织统计导出",
            key: "SiteImage-/api/count/wscountbcexport",
          },
          {
            title: "按主要负责人统计导出",
            key: "SiteImage-/api/count/wscountbossexport",
          },
          ,
          {
            title: "按工地统计导出",
            key: "SiteImage-/api/count/wscountwsexport",
          },
        ],
      },
    ],
  },
  {
    title: "设备管理",
    key: "6",
    disabled: true,
    children: [
      {
        title: "设备列表",
        key: "DeviceList",
        children: [
          {
            title: "导出设备累计时长",
            key: "DeviceList-/api/device/deviceonoffexport",
          },
          { title: "设备分配", key: "DeviceList-/api/device/distribution" },
          { title: "导出", key: "DeviceList-/api/device/deviceexport" },
          { title: "直播", key: "DeviceList-/api/worksite/camera/video" },
          { title: "绑定记录", key: "DeviceList-/api/device/bindaction" },
          { title: "删除", key: "DeviceList-/api/device/deldevice" },
          { title: "修改", key: "DeviceList-/api/device/updatedevice" },
        ],
      },
      {
        title: "设备库存",
        key: "DeviceStock",
        children: [
          { title: "导入设备账号表格", key: "DeviceStock-/api/device/import" },
          {
            title: "导入设备发货分配表格",
            key: "DeviceStock-/api/device/distributionimport",
          },
          { title: "设备分配", key: "DeviceStock-/api/device/distribution" },
        ],
      },
      {
        title: "设备统计",
        key: "DeviceStatistics",
        children: [
          {
            title: "按组织统计导出",
            key: "DeviceStatistics-/api/count/dcountbcexport",
          },
          {
            title: "按负责人统计导出",
            key: "DeviceStatistics-/api/count/dcountwbossexport",
          },
          {
            title: "按组织统计历史分析",
            key: "DeviceStatistics-/api/count/dcountbchistory",
          },
          {
            title: "按负责人统计历史分析",
            key: "DeviceStatistics-/api/count/dcountwbosshistory",
          },
        ],
      },
    ],
  },

  {
    title: "权限管理",
    key: "7",
    disabled: true,
    children: [
      // { title: "角色权限", key: "RoleList" },
      {
        title: "用户权限",
        key: "UserList",
        children: [
          { title: "添加用户", key: "UserList-/api/user/adduser" },
          { title: "查看", key: "UserList-/api/user/userdetails" },
          { title: "编辑", key: "UserList-/api/user/edituser" },
          { title: "删除", key: "UserList-/api/user/deluser" },
        ],
      },
      {
        title: "角色管理",
        key: "RoleList",
        children: [
          { title: "添加用户", key: "RoleList-/api/role/addrole" },
          { title: "查看", key: "RoleList-/api/role/details" },
          { title: "编辑", key: "RoleList-/api/role/editrole" },
          { title: "删除", key: "RoleList-/api/role/delrole" },
        ],
      },
      {
        title: "组织管理",
        key: "Organization",
        children: [
          {
            title: "添加子集",
            key: "Organization-/api/companystore/addcompanystore",
          },
          {
            title: "编辑",
            key: "Organization-/api/companystore/updatecompanystore",
          },
          {
            title: "删除",
            key: "Organization-/api/companystore/deletecompanystore",
          },
          {
            title: "添加分公司",
            key: "Organization-/api/company/addcompany",
          },
          {
            title: "修改分公司",
            key: "Organization-/api/company/updatecompany",
          },
          {
            title: "删除分公司",
            key: "Organization-/api/company/delcompany",
          },
        ],
      },
      {
        title: "个性化设置",
        key: "PersonalizedView",
        children: [
          { title: "编辑", key: "PersonalizedView-/api/setSelf/update" },
          {
            title: "查看修改记录",
            key: "PersonalizedView-/api/userLog/selectByUlBase",
          },
        ],
      },
    ],
  },

  // {
  //   title: "库存与订单",
  //   key: "7",
  //   disabled: true,
  //   children: [{ title: "库存管理", key: "DeviceStockList" }],
  // },
];
// 交付系统
const PageJF = [
  {
    title: "模版管理",
    key: "0",
    disabled: true,
    children: [
      // { title: "角色权限", key: "RoleList" },
      {
        title: "材料类别",
        key: "MaterialCategories",
        children: [
          {
            title: "添加材料类别",
            key: "MaterialCategories-/api/materialType/add",
          },
          { title: "修改", key: "MaterialCategories-/api/materialType/update" },
          { title: "删除", key: "MaterialCategories-/api/materialType/delete" },
        ],
      },
      {
        title: "材料品牌",
        key: "MaterialBrand",
        children: [
          {
            title: "添加材料品牌",
            key: "MaterialBrand-/api/materialBrand/add",
          },
          { title: "修改", key: "MaterialBrand-/api/materialBrand/update" },
          { title: "删除", key: "MaterialBrand-/api/materialBrand/delete" },
        ],
      },
      {
        title: "材料信息",
        key: "MaterialInfo",
        children: [
          {
            title: "添加材料品牌",
            key: "MaterialInfo-/api/material/add",
          },
          { title: "修改", key: "MaterialInfo-/api/material/update" },
          { title: "删除", key: "MaterialInfo-/api/material/delete" },
        ],
      },
      {
        title: "施工工序",
        key: "ConstructProcedure",
        children: [
          {
            title: "添加施工工序",
            key: "ConstructProcedure-/api/workProce/add",
          },
          { title: "修改", key: "ConstructProcedure-/api/workProce/update" },
          { title: "删除", key: "ConstructProcedure-/api/workProce/delete" },
        ],
      },
      {
        title: "施工项类别",
        key: "ConstructType",
        children: [
          {
            title: "添加施工项类别",
            key: "ConstructType-/api/projectType/add",
          },
          { title: "修改", key: "ConstructType-/api/projectType/update" },
          { title: "删除", key: "ConstructType-/api/projectType/delete" },
        ],
      },
      {
        title: "施工项",
        key: "ConstructionProject",
        children: [
          {
            title: "添加施工项",
            key: "ConstructionProject-/api/project/add",
          },
          { title: "修改", key: "ConstructionProject-/api/project/update" },
          { title: "删除", key: "ConstructionProject-/api/project/delete" },
        ],
      },
      {
        title: "套餐类别",
        key: "PackageType",
        children: [
          {
            title: "添加套餐类别",
            key: "PackageType-/api/packageType/add",
          },
          { title: "修改", key: "PackageType-/api/packageType/update" },
          { title: "删除", key: "PackageType-/api/packageType/delete" },
        ],
      },
      {
        title: "额外收费项",
        key: "ExtraCharge",
        children: [
          {
            title: "添加额外收费项",
            key: "ExtraCharge-/api/extraCharges/add",
          },
          { title: "修改", key: "ExtraCharge-/api/extraCharges/update" },
          { title: "删除", key: "ExtraCharge-/api/extraCharges/delete" },
        ],
      },
    ],
  },
  {
    title: "套餐管理",
    key: "1",
    disabled: true,
    children: [
      {
        title: "套餐模版",
        key: "PackageTemplate",
        children: [
          {
            title: "添加套餐模版",
            key: "PackageTemplate-/api/packageTemplate/add",
          },
          { title: "修改", key: "PackageTemplate-/api/packageTemplate/update" },
          { title: "删除", key: "PackageTemplate-/api/packageTemplate/delete" },
        ],
      },
      {
        title: "新建套餐",
        key: "BuildPackage",
        children: [
          {
            title: "新建套餐模版",
            key: "BuildPackage-/api/packageDetail/add",
          },
          { title: "修改", key: "BuildPackage-/api/packageDetail/update" },
          { title: "删除", key: "BuildPackage-/api/packageDetail/delete" },
        ],
      },
    ],
  },
  {
    title: "报价管理",
    key: "2",
    disabled: true,
    children: [
      {
        title: "报价列表",
        key: "QuotationList",
        children: [
          {
            title: "添加报价模版",
            key: "QuotationList-/api/quotation/add",
          },
          {
            title: "转化进度",
            key: "QuotationList-/api/quotation/generateContractQuotation",
          },
          { title: "修改", key: "QuotationList-/api/quotation/update" },
          { title: "删除", key: "QuotationList-/api/quotation/delete" },
        ],
      },
      {
        title: "合同管理",
        key: "ContractManagement",
        children: [
          {
            title: "添加合同",
            key: "ContractManagement-/api/contract/add",
          },

          {
            title: "编辑",
            key: "ContractManagement-/api/contract/update",
          },
          { title: "删除", key: "ContractManagement-/api/contract/delete" },
        ],
      },
      {
        title: "签约订单",
        key: "ContractOrderlist",
        children: [
          { title: "修改", key: "ContractOrderlist-/api/quotation/update" },
          { title: "删除", key: "ContractOrderlist-/api/quotation/delete" },
        ],
      },
    ],
  },
  {
    title: "排期管理",
    key: "3",
    disabled: true,
    children: [
      {
        title: "排期列表",
        key: "ScheduleList",
        children: [
          {
            title: "添加排期模版",
            key: "ScheduleList-/api/constructionPlan/add",
          },
          { title: "修改", key: "ScheduleList-/api/constructionPlan/update" },
          { title: "删除", key: "ScheduleList-/api/constructionPlan/delete" },
        ],
      },
    ],
  },
  {
    title: "派单管理",
    key: "4",
    disabled: true,
    children: [
      {
        title: "已排期施工项",
        key: "ScheduledConstruction",
        children: [
          {
            title: "生成派单项",
            key: "ScheduledConstruction-/api/constructionDetail/operation",
          },
          {
            title: "合并施工项",
            key: "ScheduledConstruction-/api/constructionDetail/operation",
          },
        ],
      },
      {
        title: "派单信息",
        key: "UndispatchedOrder",
        children: [
          {
            title: "派单",
            key: "UndispatchedOrder-/api/constructionDetail/update",
          },
        ],
      },
      {
        title: "已派单",
        key: "OrderSent",
        children: [
          {
            title: "取消派单",
            key: "OrderSent-/api/constructionDetail/update",
          },
        ],
      },
    ],
  },
  {
    title: "物料管理",
    key: "5",
    disabled: true,
    children: [
      {
        title: "物料下单情况",
        key: "WaitingOrder",
        children: [
          {
            title: "下单",
            key: "WaitingOrder-/api/constructionMaterial/addOrder",
          },
          {
            title: "变更供应商",
            key: "WaitingOrder-/api/material/selectById",
          },
        ],
      },
      {
        title: "订单列表",
        key: "AlreadyOrder",
        children: [
          {
            title: "取消",
            key: "AlreadyOrder-/api/order/delete",
          },
          { title: "详情", key: "AlreadyOrder-/api/order/list" },
        ],
      },
      {
        title: "订单列表/订单详情",
        key: "OrderDetails",
        children: [
          // { title: "退货", key: "OrderDetails-/api/order/list" },
        ],
      },
      {
        title: "发货查询",
        key: "TakeDelivery",
        children: [],
      },
      {
        title: "发货查询/订单详情",
        key: "ReceiptDetails",
        children: [
          // { title: "退货", key: "ReceiptDetails-/api/order/list" },
        ],
      },
      {
        title: "退换货",
        key: "ChangeGoods",
        children: [
          // { title: "退货", key: "ReceiptDetails-/api/order/list" },
        ],
      },
      {
        title: "退换货详情",
        key: "ChangeGoodsDetails",
        children: [
          // { title: "退货", key: "ReceiptDetails-/api/order/list" },
        ],
      },
    ],
  },
];
export default Page;
export { PageJF };
