<template>
  <div>
    <s-search :queryItems="queryItems" @fetchData="fetchRoles"></s-search>
    <div class="table-button left">
      <a-button
        type="plain"
        icon="plus"
        @click="openSubpage('add', '添加')"
        v-if="isShowBtns.indexOf('RoleList-/api/role/addrole') > -1"
        >添加角色</a-button
      ><a-button @click="refresh">刷新</a-button>
    </div>
    <s-table
      ref="table"
      size="default"
      rowKey="r_id"
      :columns="columns"
      :data="loadData"
      :showPagination="true"
      :pagination="true"
    >
      <span slot="action" slot-scope="text, record">
        <a
          class="text-btn"
          @click="openSubpage('view', '查看', record)"
          v-if="isShowBtns.indexOf('RoleList-/api/role/rolelist') > -1"
          >查看</a
        >
        <a
          class="text-btn"
          @click="openSubpage('edit', '编辑', record)"
          v-if="isShowBtns.indexOf('RoleList-/api/role/editrole') > -1"
          >编辑</a
        >
        <a
          class="text-btn danger"
          @click="handleDelete(record)"
          v-if="isShowBtns.indexOf('RoleList-/api/role/delrole') > -1"
          >删除</a
        >
      </span>
    </s-table>
    <div style="height: 16px"></div>
    <a-drawer
      :title="title"
      placement="right"
      :width="750"
      :visible="visible"
      @close="onClose"
      :destroyOnClose="true"
    >
      <role-view
        :roledata="roledata"
        v-if="childPage === 'view'"
        @onClose="onClose"
        ref="RoleView"
      ></role-view>
      <role-edit
        :roledata="roledata"
        :childPage="childPage"
        v-if="childPage === 'edit' || childPage === 'add'"
        @onClose="onClose"
        ref="RoleEdit"
      ></role-edit>
    </a-drawer>
    <!-- 删除 -->
    <a-modal
      class="tips-modal"
      title="提示"
      okText="确定"
      cancelText="取消"
      :visible="visibleDel"
      :confirm-loading="confirmDelLoading"
      @ok="deleteOk"
      @cancel="deleteCancel"
    >
      <p>确定删除{{ roleName }}？</p>
    </a-modal>
  </div>
</template>

<script>
import { STable, SSearch } from "@/components";
import { GetRoles, GetRoleDetail, DeleteRole } from "@/api/role";
import RoleView from "./RoleView.vue";
import RoleEdit from "./RoleEdit.vue";
const queryItems = [
  {
    label: "用户所属分公司",
    value: "r_bc_id",
    type: "select",
    selectOption: "CompanyList",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "角色ID",
    dataIndex: "r_id",
    width: "80px",
  },
  {
    title: "角色名称",
    dataIndex: "r_name",
    width: "130px",
  },
  {
    title: "角色描述",
    dataIndex: "r_describe",
  },
  {
    title: "平台权限",
    dataIndex: "r_belong_systems_name",
    width: "240px",
  },
  {
    title: "所属分公司",
    dataIndex: "r_bc_name",
    width: "200px",
  },
  {
    title: "操作",
    dataIndex: "action",
    width: "180px",
    scopedSlots: { customRender: "action" },
  },
];
const orderParam = [
  "r_id",
  "r_name",
  "r_describe",
  "r_bc_id",
  "r_belong_systems",
  "pageNo",
  "pageSize",
];

export default {
  name: "RoleList",
  components: {
    STable,
    RoleView,
    RoleEdit,
    SSearch,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      // 查询参数
      queryParam: {},
      // 编辑，查看
      title: "",
      childPage: "",
      visible: false,
      confirmLoading: false,
      // 删除
      visibleDel: false,
      confirmDelLoading: false,
      //角色信息
      roledata: {},
      roleName: "",
      isShowBtns: [],
      //当前页数，当前条数
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {},
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtns").split(",");
  },
  methods: {
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      this.pageNo = parameter.pageNo * 1;
      this.pageSize = parameter.pageSize;
      // const requestParameters = Object.assign({}, parameter, this.queryParam);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return GetRoles(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;
        return res.data;
      });
    },
    fetchRoles(value) {
      this.queryParam = value;
      this.$refs.table.refresh(true);
    },
    openSubpage(page, name, record) {
      // console.log(page, name, record);
      if (page === "edit" || page === "view") {
        let params = {
          r_id: record.r_id,
        };
        GetRoleDetail(params).then((res) => {
          // console.log("detailres", res.data);
          res.data.r_id = record.r_id;
          this.roledata = res.data; //获取后端被选中的页面和按钮，传给子组件
          this.roledata.r_bc_id = record.r_bc_id == 0 ? "" : record.r_bc_id;
          this.visible = true;
          this.childPage = page;
          // this.title = "权限管理-角色权限" + name;
          this.title = "权限管理-角色管理" + name;
        });
      } else {
        this.visible = true;
        this.childPage = page;
        // this.title = "权限管理-角色权限" + name;
        this.title = "权限管理-角色管理" + name;
      }
    },
    onClose() {
      this.visible = false;
      this.roledata = {};
      setTimeout(() => {
        this.$refs.table.refresh(true);
      }, 100);
      // location.reload(true);
    },
    // 删除
    handleDelete(record) {
      this.visibleDel = true;
      this.roledata.r_id = record.r_id;
      this.roleName = record.r_name;
    },
    deleteOk() {
      //删除角色
      let params = {
        r_id: this.roledata.r_id,
      };
      DeleteRole(params).then((res) => {
        if (res.code == "200") {
          this.$message.success(this.roleName + res.data);
        } else {
          this.$message.error(this.roleName + res.data);
        }
      });
      this.visibleDel = false;
      //刷新列表
      this.$refs.table.refresh(true);
      this.roledata = {};
    },
    deleteCancel() {
      this.visibleDel = false;
      this.$refs.table.refresh(true);
      this.roledata = {};
    },
    // 刷新
    refresh() {
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.text-btn + .text-btn {
  margin-left: 16px;
}
.danger {
  color: #ff4d4f;
}
.left {
  text-align: right;
}
</style>
