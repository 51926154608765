<template>
  <div>
    <div class="role-title">{{ "角色：" + roledata.r_name }}</div>
    <div class="destri-cont">
      <div class="page-title">可见页面</div>
      <div v-for="(item, index) in pagesdata" :key="index" class="page-cont">
        <div class="item-title">{{ item.title }}</div>
        <div>
          <div
            v-for="(child, j) in item.children"
            :key="j"
            class="pagesdata_title"
          >
            {{ child.title }}
            <span
              v-for="(childItem, index) in child.children"
              :key="index"
              class="pagesdata_children_title"
            >
              {{ childItem ? childItem.title : "" }}
            </span>
          </div>
        </div>
      </div>
      <div class="dest-bottom" style="margin-top: 20px">
        <a-button @click="viewCancel()">返回</a-button>
      </div>
    </div>
  </div>
</template>
<script>
import Page from "@/utils/page";

export default {
  name: "RoleView",
  props: {
    roledata: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      pages: [],
      pagesdata: {},
      // forEachPages: {},
    };
  },
  created() {
    //页面权限判断
    // 这个是，父组件的获取后端被选中的页面和按钮。是字符串
    this.roledata.r_right = this.roledata.r_right.split(",");
  },
  mounted() {
    this.getData();
  },
  watch: {
    roledata: {
      handler: function (newValue, oldValue) {},
      immediate: true,
      deep: true,
    },

    deep: true,
    immediate: true,
  },
  methods: {
    getData() {
      this.pagesdata = {};
      this.pages = JSON.parse(JSON.stringify(Page));
      //功能判断
      let pageArrs = [];
      for (let i = 0; i < this.pages.length; i++) {
        let pageArr = [];

        for (let k = 0; k < this.pages[i].children.length; k++) {
          if (
            this.roledata.r_right.indexOf(this.pages[i].children[k].key) != -1
          ) {
            pageArr.push(this.pages[i].children[k]);
          }
        }
        if (pageArr.length != 0) {
          let pageObject = {};
          pageObject.title = this.pages[i].title;
          pageObject.children = pageArr;

          pageArrs.push(pageObject);
        }
      }
      this.pagesdata = pageArrs;
      this.pagesdata.forEach((el, index1) => {
        el.children.forEach((item, index2) => {
          let child = [];
          item.children.forEach((ele, index3) => {
            if (this.roledata.r_right.indexOf(ele.key) > -1) {
              child.push(ele);
            } else {
            }
          });
          item.children = child;
        });
      });
    },
    // 取消
    viewCancel() {
      this.$emit("onClose");
    },
  },
};
</script>
<style lang="less" scoped>
.destri-cont {
  padding: 24px;
  background-color: #fff;
}
.dest-bottom {
  text-align: right;
}
.role-title {
  font-weight: 500;
  font-size: 14px;
  color: #333;
  margin-bottom: 24px;
}
.page-title {
  font-weight: 600;
  font-size: 16px;
  color: #333;
  margin-bottom: 24px;
}
.page-cont + .page-cont {
  margin-top: 20px;
}
.item-title {
  font-weight: 600;
  margin-bottom: 12px;
  font-size: 14px;
}
.pagesdata_title {
  font-size: 12px;
  font-weight: 600;
}
.pagesdata_children_title {
  font-weight: 400;
}
</style>
